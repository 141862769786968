
export default {
  props: {
    formId: {
      type: [Number, String],
      required: true,
      default: 0
    },
    formData: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Object | Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      form_hide: false,
      form_thankyou: false,
      loader: true,
      cssText: "",
      scrollOffset: 450
    };
  },
  head() {
    return {
      style: [{ cssText: this.formData.form_css, type: "text/css" }],
      // script: [
      //   {
      //     hid: "clearbit_markto_script",
      //     type: "text/javascript",
      //     innerHTML: `var pollForDefinition = function (scope, varname, callback) {
      //       if (typeof scope[varname] !== "undefined") {
      //         return callback();
      //       }
      //       var interval = setInterval(function () {
      //         if (typeof scope[varname] !== "undefined") {
      //           clearInterval(interval);
      //           callback();
      //         }
      //       }, 250);
      //     };
      //     var script = document.createElement("script");
      //     script.src = "https://marketo.clearbit.com/assets/v1/marketo/forms.js";
      //     script.async = true;
      //     script.setAttribute("data-clearbit-publishable-key", "pk_1ddf31a8b530518e6d063415b1d502da");
      //     script.onerror = function (e) {
      //       console.log("Clearbit Form JS unable to load");
      //       pollForDefinition(window, "MktoForms2", function () {
      //         MktoForms2.whenReady(function (form) {
      //           form.setValues({ clearbitFormStatus: "Clearbit Form JS unable to load" });
      //         });
      //       });
      //     };
      //     document.querySelector('head').appendChild(script);`
      //   }
      // ]
    };
  },
  mounted() {
    if (!process.server && !window.MktoForms2) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "//app-sjp.marketo.com/js/forms2/js/forms2.min.js";
      document.head.appendChild(script);
      script.onload = this.loadMarktoForm;
    } else {
      this.loadMarktoForm();
    }
  },
  methods: {
    scrollToTop() {
      const ele = this.$refs.marketoForm;
      if (ele) {
        const scrollToOptions = {
          behavior: "smooth",
          block: "start"
        };
        // ele.scrollIntoView(scrollToOptions);
        const offset = this.scrollOffset; // Get the offset from data
        ele.scrollIntoView(scrollToOptions);
        window.scrollBy(0, -offset); // Scroll up by the specified offset
      }
    },
    noScrollToRop(){
      const ele = this.$refs.marketoForm;
      if (ele) {
        const offset = this.scrollOffset; // Get the offset from data
        window.scrollBy(0); // Scroll up by the specified offset
      }
    },
    loadMarktoForm() {
      var marketoID = parseInt(this.formId);
      let _vm = this;
      // eslint-disable-next-line no-undef
      if (undefined !== window.MktoForms2) {
        // eslint-disable-next-line no-undef
        window.MktoForms2.loadForm(
          "//app-sjp.marketo.com",
          "362-YWJ-377",
          marketoID,
          function (form) {
            _vm.loader = false;
            form.onSuccess(function (value) {
              if (_vm.formData) {
                if (_vm.formData.eventData) {
                  _vm.$gtm.push({
                    event: _vm.formData.eventData.event,
                    eventCategory: _vm.formData.eventData.category,
                    eventAction: _vm.formData.eventData.action,
                    eventLabel: _vm.formData.eventData.label
                  });
                  // _vm.$gtm.push({
                  //   event: "ClearbitFormSubmit",
                  //   email: value.Email
                  // });
                }
                if (_vm.formData.form_type === "form1") {
                  _vm.form_hide = true;
                  if(_vm.formData.hasOwnProperty('noScroll')){
                    if(_vm.formData.moScroll === true){
                      _vm.noScrollToRop();
                    }
                  }else{
                    // Scroll to the top of the thankYouSection
                    _vm.scrollToTop();
                  }
                  if (_vm.formData.form1_section.thank_you_message) {
                    _vm.form_thankyou =
                      _vm.formData.form1_section.thank_you_message;
                  } else {
                    _vm.form_thankyou = "Thank You for your submission!";
                  }
                  _vm.$emit("handle_form_submission_success",true)
                  _vm.$emit("formSubmitted");
                  return false;
                }
                if (
                  _vm.formData.form_type === "form2" &&
                  _vm.formData.form2_section.overide_redirect
                ) {
                  let url = _vm.formData.form2_section.redirection_url;

                  if (_vm.isUrl(url)) {
                    document.location.href = url;
                  } else {
                    _vm.$nuxt.$off("open-popup-form");
                    _vm.$router.push({ path: url });
                  }
                  return false;
                }
              }
              _vm.$router.push("/thank-you");
              return false;
            });
            //Validate Business Email
            form.onValidate(function () {
              var email = form.vals().Email;
              if (email) {
                if (!_vm.isEmailGood(email)) {
                  form.submittable(false);
                  var emailElem = form.getFormElem().find("#Email");
                  form.showErrorMessage("Must be Business email.", emailElem);
                  emailElem.focus();
                } else {
                  form.submittable(true);
                }
              }
            });
          }
        );
        window.MktoForms2.whenReady(function (form) {
          let inputs = document.querySelectorAll(
            ".mktoFormRow input[type=hidden], .mktoPlaceholder"
          );
          inputs.forEach((ele) => {
            ele.parentNode.classList.add("m-0");
          });

          //new form design
          let formElement = form.getFormElem()[0];

          let marktoForm = formElement.querySelectorAll(".mktoFieldWrap");
          for (let ele in marktoForm) {
            if (marktoForm[ele] instanceof Element) {
              if (
                marktoForm[ele].querySelectorAll(".mktoCheckboxList").length
              ) {
                marktoForm[ele].classList.add("normalLable");
                let checkbox = marktoForm[ele]
                  .querySelectorAll(".mktoCheckboxList")[0]
                  .querySelectorAll("input[type=checkbox]");

                if (checkbox.length > 1) {
                  checkbox = marktoForm[ele]
                    .querySelectorAll(".mktoCheckboxList")[0]
                    .classList.add("multiCheck");
                }
              }
            }
          }

          formElement.addEventListener("DOMNodeInserted", function (e) {
            let element = e.target;

            if (element.innerHTML === "") {
              element.parentNode.classList.add("m-0");
            } else {
              element.parentNode.classList.remove("m-0");
            }

            if(element instanceof Element) {
              let inputFields = element.getElementsByClassName("mktoField");
              let labels = element.getElementsByClassName("mktoLabel");
              for (const inputField of inputFields) {
                _vm.fieldFocused(inputField);
              }
              for (const label of labels) {
                _vm.fieldFocused(label);
              }

            }
            
          });

          let allInputs = formElement.querySelectorAll(".mktoField");
          let labels = formElement.querySelectorAll(".mktoLabel");
          for (const allInput of allInputs) {
            _vm.fieldFocused(allInput);
          }
          for (const label of labels) {
            _vm.fieldFocused(label);
          }

          // T&C short and pull action
          try {
            if (!formElement.closest(".marketo-custom-form")) {
              return;
            }
            let tc_label = formElement.querySelector(
              `#mktoForm_${_vm.formId} input[name=Receive_TCL_Communique__c]`
            );

            let full_tc_label =
              tc_label.parentElement.getElementsByTagName("label")[0];

            let default_tc_text = "";
            // let short_tc_text = "<p>I agree to the Terms and Conditions</p>";
            if (full_tc_label) {
              default_tc_text = full_tc_label.innerHTML;
              // full_tc_label.innerHTML = short_tc_text;
            }

            if (allInputs.length > 1) {
              let firstElement = allInputs[0];
              // let textAdded = false;
              firstElement.addEventListener("focus", function () {
                // if (!textAdded) {
                //   full_tc_label.innerHTML = 
                //   short_tc_text + "<p class='mt-2'>" +
                //     default_tc_text +
                //     "</p>";
                //   textAdded = true;
                // }
              });
            }
          } catch (e) {
            console.error(e);
          }
        });
      }
    },
    isEmailGood(email) {
      var invalidDomains = [
        "@gmail.",
        "@yahoo.",
        "@hotmail.",
        "@live.",
        "@aol.",
        "@outlook."
      ];
      for (var i = 0; i < invalidDomains.length; i++) {
        var domain = invalidDomains[i];
        if (email.indexOf(domain) !== -1) {
          return false;
        }
      }
      return true;
    },
    fieldFocused(fieldObject) {
      if (fieldObject.tagName === "LABEL") {
        if (fieldObject.getAttribute("clickListener") !== "true") {
          fieldObject.addEventListener("click", function (e) {
            fieldObject.parentNode.classList.add("active");
            e.target.setAttribute("clickListener", "true");
            let inputField = fieldObject.parentNode.querySelector(".mktoField");
            if (inputField instanceof Element) {
              inputField.focus();
            }
          });
        }
      } else {
        fieldObject.placeholder = "";
        if (fieldObject.type === "select-one") {
          fieldObject.parentNode.classList.add("active");
        }
        if (
          fieldObject.type !== "hidden" ||
          fieldObject.type !== "checkbox" ||
          fieldObject.type !== "select-one"
        ) {
          if (fieldObject.getAttribute("focusListener") !== "true") {
            fieldObject.addEventListener("focus", function (e) {
              fieldObject.parentNode.classList.add("active");
              e.target.setAttribute("focusListener", "true");
            });
          }
          if (fieldObject.getAttribute("blurListener") !== "true") {
            fieldObject.addEventListener("blur", function (e) {
              if (!fieldObject.value && e.target.type !== "select-one") {
                fieldObject.parentNode.classList.remove("active");
              }
            });
          }
        }
      }
    }
  }
};
