
import TataLink from "~/components/global-components/tata-link";
export default {
  components: { TataLink },
  props: {
    showMenu: {
      type: Boolean,
      default: false
    },
    menuData: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    contactUs: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {}
    }
  },
  data: () => {
    return {
      active_el: null,
      remove_el: null,
      freetrial_el:null,
      accordianIndex:null,
      showfreeTrail:false,
    };
  },
  watch: {
    $route() {
      this.active_el = null;
      this.freetrial_el= null;
    },
    showMenu() {
      this.active_el = null;
      this.freetrial_el= null;
    }
  },
  methods: {
    deactivate: function () {
      this.active_el = null;
    },
    activate: function (el) {
      this.active_el = el;
      this.freetrial_el= el;
    },
    handleAccordianIndex (val){
      if(val === this.accordianIndex){
        this.accordianIndex = null;
      }else{
        this.accordianIndex = val;
      }
    },
    slidetrailMenus(bool){
      this.showfreeTrail = bool;
    }
  }
};
