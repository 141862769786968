
  import { mapState } from "vuex";
  import vueCustomScrollbar from "vue-custom-scrollbar";
  import TataLink from "~/components/global-components/tata-link";
  import TataMobileNavigation from "~/components/global-components/tata-mobile-navigation-new";
  
  export default {
    components: {
      TataMobileNavigation,
      TataLink,
      vueCustomScrollbar
    },
    beforeRouteEnter() {
      let { store } = this.$nuxt.context;
      //store.dispatch("l1/updatePageTitle", "");
      store.dispatch("l1/updateItemSpecificData", null);
      this.showCustomDropdown = false;
    },
    emit: ["toggleOverlayView"],
    data: () => {
      return {
        mainMenuToggler: false,
        showMenu: false,
        showMegaMenu: false,
        showCustomDropdown: false,
        customDropdownVal: false,
        subMegaContent: false,
        submenuContent: false,
        activeIndex: -1,
        activeMenuIndex: 0,
        defaulltRightMenuIndex: 0,
        showSecondaryMenu: null,
        activerSecondaryIndex:0,
        activeTitleLink: null,
        activeTitleIndex: -1,
        activeMegaIndex: 0,
        activeSubmenu: false,
        menuActiveIndex: -1,
        levelTwoMenu: false,
        navChildMenu: false,
        navChildSubMenu: false,
        activeChildSubMenuIndex: -1,
        searchFlag: false,
        mobSearchBox: false,
        showChildPageDropdown: false,
        levelTwoIndex: -1,
        levelTwoItem: {},
        levelThreeMenu: false,
        levelThreeItem: {},
        levelThreeIndex: -1,
        search: "",
        searchData: false,
        isOpen: false,
        settings: {
          suppressScrollY: false,
          suppressScrollX: true,
          wheelPropagation: false
        },
        notification_banner_closed: false,
        megaMenuOverlay: null,
        desktopMenu: null
      };
    },
    computed: {
      ...mapState(["menuData"]),
      ...mapState("l1", ["pageTitle"]),
      ...mapState("l1", ["itemSpecificData"]),
      headerMenu: function () {
        if (this.menuData) {
          return this.menuData.header_menus;
        } else {
          return [];
        }
      }
    },
    watch: {
      async search(val) {
        if (val.length > 2) {
          let payload = { keyword: val, per_page: 10 };
          const res = await this.$axios.get("/search", {
            params: payload,
            isLoading: false
          });
          if (res.data) {
            this.searchData = res.data.records.page;
            this.isOpen = true;
          }
        } else {
          this.searchData = false;
        }
      },
      $route() {
        this.search = "";
        this.searchData = false;
        this.mainMenuToggler = false;
        this.navChildMenu = false;
        this.isOpen = false;
        this.showCustomDropdown = false;
        this.levelThreeMenu = false;
        this.levelTwoMenu = false;
        this.showMenu = false;
        this.activeSubmenu = false;
        this.menuActiveIndex = -1;
        this.levelThreeIndex = -1;
        this.levelThreeItem = {};
        this.levelTwoIndex = -1;
        this.levelTwoItem = {};
        this.notification_banner_closed = false;
        this.mobSearchBox = false;
        this.$disposSearch();
        setTimeout(() => {
          if (
            document.querySelector("#mobile_search") &&
            document.querySelector("#nav__search-input")
          ) {
            this.$initalizedSearch();
          }
        }, 500);
      }
    },
    head() {
      return {
        bodyAttrs: {
          class: this.hasNotificationBanner() ? "show-notification-banner" : ""
        }
      };
    },
    created() {
      if (process.client && this.hasNotificationBanner()) {
        window.addEventListener("scroll", this.handleScroll);
      }
    },
    destroyed() {
      if (process.client && this.hasNotificationBanner()) {
        window.removeEventListener("scroll", this.handleScroll);
      }
    },
    mounted() {
      if (
        this.$route.path === "/solutions/network/secure-network-transformation/"
      ) {
        const imgScript = document.createElement("img");
        imgScript.src =
          "https://wdc-rtb-events.adnear.net/v2/events/ct.gif?a=ET4";
        imgScript.width = "1";
        imgScript.height = "1";
        document.body.insertBefore(imgScript, document.body.firstChild);
      }

      if (this.$route.path === "/customer-experience/") {
        const imgScript = document.createElement("img");
        imgScript.src =
          "https://wdc-rtb-events.adnear.net/v2/events/ct.gif?a=ET3";
        imgScript.width = "1";
        imgScript.height = "1";
        document.body.insertBefore(imgScript, document.body.firstChild);
      }

      this.$disposSearch();
      if (
        document.querySelector("#mobile_search") &&
        document.querySelector("#nav__search-input")
      ) {
        this.$initalizedSearch();
      }
      document.addEventListener("click", this.handleClickOutside);
    },
    methods: {
      clickInside() {
        this.showMegaMenu = false;
      },
      toggleMegaMenuOverlay(param) {
        this.$emit("toggleOverlayView", param);
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
        this.levelThreeMenu = false;
        this.levelTwoMenu = false;
        this.activeSubmenu = false;
      },
      contact_us_cta() {
        if (
          this.itemSpecificData &&
          this.itemSpecificData.contact_us_cta &&
          this.itemSpecificData.contact_us_cta.text
        ) {
          return this.itemSpecificData.contact_us_cta;
        } else {
          return { text: "Contact Us", url: "/contact-us", open_in: "Same Tab" };
        }
      },
      submitSearch() {
        this.$router.push(`/search?q=${this.search}`);
      },
      handleClickOutside(evt) {
        if (!this.$el.contains(evt.target)) {
          this.isOpen = false;
        }
      },
      isCountryPage() {
        return this.itemSpecificData &&
          this.itemSpecificData
            .should_the_api_for_this_page_or_item_have_header_and_footer_menu
          ? this.itemSpecificData
              .should_the_api_for_this_page_or_item_have_header_and_footer_menu
          : false;
      },
      notificationBanner() {
        this.notification_banner_closed = true;
        document.getElementById("notification-banner").classList.add("d-none");
        document
          .getElementsByTagName("body")[0]
          .classList.remove("show-notification-banner");
      },
      hasNotificationBanner() {
        return !!this.itemSpecificData?.notification_banner?.title;
      },
      handleScroll() {
        if (
          !this.notification_banner_closed &&
          document.getElementById("notification-banner")
        ) {
          if (window.scrollY >= 100) {
            document
              .getElementById("notification-banner")
              .classList.add("d-none");
            document
              .getElementsByTagName("body")[0]
              .classList.remove("show-notification-banner");
          } else {
            document
              .getElementById("notification-banner")
              .classList.remove("d-none");
            document
              .getElementsByTagName("body")[0]
              .classList.add("show-notification-banner");
          }
        }
      }
    }
  };
