
    export default{
        data(){
            return{
                nudgeData:{
                    pageURL:null,
                    trackerEventName:null,
                },
            }
        },
        mounted() {
            this.storeNudgeData();
        },
        methods: {
            storeNudgeData() {
                this.nudgeData.pageURL = this.$attrs.page_url;
                this.nudgeData.trackerEventName = this.$attrs.tracker_event_name;
            },
        },
    }
