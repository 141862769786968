
import { mapState } from "vuex";

export default {
  components: {
    tataKBHeader: () => import("~/components/tataKBHeaderNew.vue"),
    tataFooter: () => import("~/components/tataFooter"),
    scrollToTopSection : () =>import("~/components/global-components/tata-scroll-to-top-cta")
  },
  scrollToTop: true,
  data: function () {
    return {
      uniqueKey: 0,
      showOverlay: false
    };
  },
  computed: {
    ...mapState("l1", ["itemSpecificData"]),
    show400: function () {
      return this.$route.query.status
        ? this.$route.query.status.indexOf("4") === 0
        : true;
    }
  },
  watch: {
    $route: function (to, from) {
      if (to.path !== from.path) this.uniqueKey++; // Adding unique key to nuxt on route change for transitions
      //  this.updateUtmPrams();
    }
  },
  // eslint-disable-next-line vue/order-in-components
  beforeRouteLeave(to, from, next) {
    next();
  },
  mounted() {
    //this.updateUtmPrams();
  },
  methods: {
    isCountryPage() {
      return this.itemSpecificData &&
        this.itemSpecificData
          .should_the_api_for_this_page_or_item_have_header_and_footer_menu
        ? this.itemSpecificData
            .should_the_api_for_this_page_or_item_have_header_and_footer_menu
        : false;
    },
    updateUtmPrams() {
      let params = new URLSearchParams(window.location.search);
      let utm_params = [];
      params.forEach(function (value, key) {
        if (key.startsWith("utm_")) {
          utm_params.push(key + "=" + value);
        }
      });
      let utm_search = utm_params.join("&");

      if (utm_search) {
        let interval = setInterval(() => {
          let links = this.$refs.mainRef.getElementsByTagName("a");
          for (let i = 0; i < links.length; i++) {
            let href = links[i].getAttribute("href");
            if (href.indexOf(utm_search) === -1 && href.indexOf("#") === -1) {
              let url_link =
                href + (href.indexOf("?") === -1 ? "?" : "&") + utm_search;
              links[i].setAttribute("href", url_link);
            }
          }
        }, 1000);
        setTimeout(() => {
          clearInterval(interval);
        }, 10000);
      }
    },
    toggleOverlayView(param) {
      this.showOverlay = param;
    }
  }
};
