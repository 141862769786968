
export default {
  data: () => {
    return {
      modalIndex: 0,
      mySwiper:null,
    }
  },
  
  mounted() {
    this.mySwiper = new Swiper(".swiper-container", {
      spaceBetween: 40,
      slidesPerView: 1.3,
      centeredSlides: true,
      initialSlide: 0,
      pagination: {
        el: ".swiper-pagination",
        type: "fraction",
      },
      navigation: {
        nextEl: ".swiper-button-next-desk",
        prevEl: ".swiper-button-prev-desk",
      },
      autoplay: {
        delay: 3500,
        pauseOnMouseEnter: true,
        disableOnInteraction: false
      },
      // roundLengths: true,
      loop: false,
      // loopAdditionalSlides: 30,

      breakpoints: {
        300: {
          slidesPerView: 1,
          spaceBetween: 20,
          centeredSlides: true,
          navigation: {
            nextEl: ".swiper-button-next-mob",
            prevEl: ".swiper-button-prev-mob",
          },
        },
        768: {
          slidesPerView: 1.3,
          spaceBetween: 40
        }
      }
    });
  },
  methods: {
    openModal(index) {
      this.modalIndex = index
      // Use jQuery or JavaScript to open the modal
      $(".Read_Interview_modal").modal("show")
    }
  }
}
