
  import { Nudge, NudgeProvider } from "nudge_core_browser";
  import { NudgesUi, NudgesStoriesUi} from "nudge_core_browser";
  import { onMounted, watch } from 'vue';

  const nudge = new Nudge({
    apiKey: process.env.NudgeCoreApiKey || "XXXXXX",
  });

  let nudgesUi = new NudgesUi();
  let nudgesStoriesUi = new NudgesStoriesUi();
  
  new NudgeProvider({
    nudgeInstance: nudge,
    plugins: [nudgesUi, nudgesStoriesUi]
  });
  
  async function initFunc(nudgeData) {
    const { pageURL, trackerEventName } = nudgeData;
    console.log("nudgeData", pageURL,trackerEventName);
    try {
        let externalId = localStorage.getItem('externalId');
        
        if (!externalId) {
            externalId = Date.now().toString();
            localStorage.setItem('externalId', externalId);
        }
        await nudge.initSession({ externalId: externalId, properties: {} }); // external id to hold a random unique number/id

        if(window.location.pathname === pageURL){
          await nudge.track({ type: trackerEventName });
        }

        if(window.location.pathname === '/'){
          await nudge.track({ type: "HOMEPAGE_STORY" });
        }
        
    } catch (err) {
      console.log(err);
    } finally {
      console.log("nudge loaded");
    }
  }
  
  export default {
    name: "NuxtTutorial",
    props:["nudgeData"],
    setup(props) {
      let isInitiated = false; // Flag to track if initFunc has been called

      onMounted(() => {
        if(window.location.pathname == '/' ){
          window.addEventListener("load", () => {
            initFunc(props.nudgeData);
          });
        }else{
          window.addEventListener("scroll", () => {
            // Calculate scroll percentage
            const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
            const scrolled = (window.scrollY / scrollHeight) * 100;
            if (scrolled >= 0 && !isInitiated) {
              initFunc(props.nudgeData);
              isInitiated = true; // Set the flag to true after calling initFunc
            }
          });
        }

        watch(() => props.nudgeData, (nudgeValue) => {
          // Call initFunc when nudgeData changes
          if (nudgeValue) {
            initFunc(nudgeValue);
          }
        });
      });
    },
    methods: {
      startDemo: async function () {
        await nudge.track({ type: "DEMO_NUDGES_EVENT" }); // type to hold a unique id specific to the nudge
      }
    }
  };
  