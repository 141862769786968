
export default {
  data() {
    return {
      formData: []
    };
  },
  mounted() {
    this.formData = this.$attrs;
    this.formData.eventData = {
      event: "TataFormSubmit",
      category: "Form submit",
      action: "Page",
      label: window.location.href
    };

    // Extract ID from URL
    const hash = window.location.hash;
    const idFromUrl = hash ? hash.substring(1) : null;
    // Check if idFromUrl is null
    if (idFromUrl === null) {
        return; 
    }
    const sectionId = idFromUrl;
    this.$attrs.reference_id = sectionId;

    // Apply offset if needed
    const element = document.getElementById(sectionId);
    setTimeout(() => {
      if (element) {
        const yOffset = 200; // Change this value to your desired offset
        const yCoordinate = element.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({ top: yCoordinate - yOffset, behavior: 'smooth' });
      }
    }, 1000);
  },
  methods: {
    isFormSubmitted() {
      if (this.$attrs.is_gmq_form) {
        document
          .getElementsByClassName("form-section")[0]
          .classList.add("d-none");
        document
          .getElementsByClassName("gmq-download-section")[0]
          .classList.remove("d-none");
        window.scrollTo({
          top:
            document.getElementsByClassName("gmq-download-section")[0]
              .offsetTop - 150,
          behavior: "smooth"
        });
      }
    }
  }
};
