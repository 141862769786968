
// import tataHeader from "~/components/tataHeader";
import tataHeader from "~/components/tataHeaderNew";
import tataFooter from "~/components/tataFooter";
import { mapState } from "vuex";

export default {
  components: {
    tataHeader: () => import("~/components/tataHeaderNew"), //tataHeader,
    tataFooter: () => import("~/components/tataFooter"), //tataFooter
    scrollToTopSection : () =>import("~/components/global-components/tata-scroll-to-top-cta")
  },
  scrollToTop: true,
  data: function () {
    return {
      uniqueKey: 0,
      showOverlay: false
    };
  },
  computed: {
    ...mapState("l1", ["itemSpecificData"]),
    show400: function () {
      return this.$route.query.status
        ? this.$route.query.status.indexOf("4") === 0
        : true;
    }
  },
  mounted() {
    window.addEventListener(
      "click",
      (event) => {
        let target = event.target;

        // If the clicked element doesn't have the right selector, bail
        if (
          !target.matches("a[data-form-popup]") &&
          !target.parentElement.matches("a[data-form-popup]")
        ) {
          return;
        }

        // Don't follow the link
        event.preventDefault();

        this.$nuxt.$emit("open-popup-form", "open");
      },
      false
    );
  },
  watch: {
    $route: function (to, from) {
      if (to.path !== from.path) this.uniqueKey++; // Adding unique key to nuxt on route change for transitions
    }
  },
  methods: {
    isCountryPage() {
      return this.itemSpecificData &&
        this.itemSpecificData
          .should_the_api_for_this_page_or_item_have_header_and_footer_menu
        ? this.itemSpecificData
            .should_the_api_for_this_page_or_item_have_header_and_footer_menu
        : false;
    },
    toggleOverlayView(param) {
      this.showOverlay = param;
    }
  }
};
